// const Main = ({ children }) => {
//     return(
//         <main className="row">
//             { children }
//         </main>
//     )
// }

// export default Main


const Main = ({ children }: any) => {
    return (
        <main className="row">{children}</main>
    )
};

export default Main;