
import Pengumuman from './pengumuman';
import Stack from 'react-bootstrap/Stack';
import AgendaKomplek from './agenda';
import SitePlan from './site_plan';
import TelpPenting from './info/telp-penting';
import SlideshowKomplek from '../slideshow';
import WargaSummary from './warga';
import Container from 'react-bootstrap/Container';
import { Row, Col } from "react-bootstrap";
import BannerIklan from './iklan/banner-iklan';
import BlokE from './iklan/blok-e';
import BannerKas from './iklan/banner-kas';
// import BlockEDetail from './iklan/blok-e-detail';
import RekeningKas from './info/rekening';
import Tunggakan from './tunggakan';
import Promote from './iklan/promote';

const Homepage = () => {
    return (
        <div className='pb-2'>
            <SlideshowKomplek></SlideshowKomplek>
            <div className='bg-white pt-3 pb-2'>
                <Container>
                    <blockquote className="blockquote text-center">
                        <p className="mb-3"><strong>Wilujeung Sumping</strong>, website ini dibuat oleh Pengurus komplek Bumi Cileunyi Asri untuk memfasilitasi warga
                            mendapatkan informasi terkait pengelolaan komplek baik fasilitas fisik, kegiatan warga, keuangan,
                            sarana promosi usaha warga dan lain sebagainya.</p>
                        <footer className="blockquote-footer">Pengurus Komplek<cite title="Source Title">BCA</cite></footer>
                    </blockquote>
                </Container>
            </div>
            <Container>
                <Row xs={1} className="g-4">
                    <Col>
                        <Stack gap={3} className="pt-3">
                            {/* <BlockEDetail/> */}
                            <div id="pengumuman">
                                <Pengumuman />
                            </div>
                            <BannerKas/>
                            <div id="agendakomplek"></div>
                            <AgendaKomplek />
                            <div id="siteplan"></div>
                            <Promote />
                            <BannerIklan />
                            <SitePlan />
                            <div id="wargasummary"></div>
                            <WargaSummary />
                            <div id="banneriklan" />
                            <div id="telppenting" />
                            <RekeningKas />
                            <Tunggakan />
                            <TelpPenting />
                            <BlokE />

                        </Stack>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Homepage;