import './App.css';
import {
  Row,
  Col
} from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import Main from './components/Main';
import Homepage from './pages/homepage';
import Iklan from './pages/iklan';
import KasWarga from './pages/kas_warga';
import KritikSaran from './pages/kritik_saran';

function App() {
  return (
    <Router>
      <Navbar bg="dark" variant="dark" sticky='top' expand={false} collapseOnSelect>
        <Container>
          <Navbar.Brand href="/#home">
            <img
              alt=""
              src="/logo-bca7.png"
              width="46"
              height="46"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Text className=''>
            BUMI CILEUNYI ASRI
          </Navbar.Text>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                Pilih informasi yang ingin dilihat
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/#pengumuman">Pengumuman</Nav.Link>
                <Nav.Link href="/#agendakomplek">Agenda Komplek</Nav.Link>
                <Nav.Link href="/#siteplan">Peta Komplek</Nav.Link>
                <Nav.Link href="/#wargasummary">Jumlah Kepala Keluarga</Nav.Link>
                <Nav.Link href="/#banneriklan">Iklan Warga</Nav.Link>
                <Nav.Link href="/#telpPenting">Informasi Penting Lainnya</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <Suspense fallback={<div>Loading...</div>}>
        <Main>
          <Routes>
            <Route path='/' element={<Homepage />} />
            <Route path='/kas' element={<KasWarga />} />
            <Route path='/iklan' element={<Iklan />} />
            <Route path='/kritiksaran' element={<KritikSaran />} />
          </Routes>
          <Row>
            <Col className='text-center p-5'>
              <span>Hak Cipta Bumi Cileunyi Asri @ 2023</span>
            </Col>
          </Row>
        </Main>
      </Suspense>
    </Router>
  );
}

export default App;
