import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function BannerIklan() {
    return (
        <Link to="/iklan">
            <Card>
                <Card.Img src="/iklan/banner-iklan.png">
                </Card.Img>
            </Card>
        </Link>
    )
}