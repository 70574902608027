import { Card, Row, Col, Container } from "react-bootstrap";
import { WalletFill, EnvelopeOpen, PiggyBank, InfoCircleFill, EmojiSmileFill,EmojiFrownFill } from 'react-bootstrap-icons'
import Badge from 'react-bootstrap/Badge';
import DataSaldoKas from '../../data/saldo-bulan-lalu.json';

export default function SaldoKas() {

    return (
        <Row>
            <Col >
                <Card className="card-stats shadow bg-white rounded'">
                    <Card.Header>
                        <Row>
                            <Col className="d-flex justify-content-between">
                                <strong>SALDO KAS</strong>
                                <Badge bg="success">
                                    <h6>{DataSaldoKas[0].periode} {DataSaldoKas[0].tahun}</h6>
                                </Badge>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Container>
                            <Row>
                                <div className="d-flex justify-content-start">
                                    <WalletFill size={24} color='orange' />
                                    <div className="mx-4 numbers">

                                        <span className="info-text">Saldo Awal</span>
                                        <Card.Text>{DataSaldoKas[0].saldoAwal.toLocaleString('en-us')}</Card.Text>
                                    </div>
                                </div>
                                <hr />
                            </Row>
                            <Row>
                                <div className="d-flex justify-content-start">
                                    <PiggyBank size={24} color='green' />
                                    <div className="mx-4 numbers">
                                        <span className="info-text">Pemasukan</span>
                                        <Card.Text>{DataSaldoKas[0].pemasukan.toLocaleString('en-us')}</Card.Text>
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className="d-flex justify-content-start">
                                    <EnvelopeOpen size={24} color='red' />
                                    <div className="mx-4 numbers">
                                        <span className="info-text">Pengeluaran</span>
                                        <Card.Text className="text-danger">{DataSaldoKas[0].pengeluaran.toLocaleString('us-en')}</Card.Text>
                                    </div>
                                </div>
                                <hr />
                            </Row>
                            <Row>
                                <div className="d-flex justify-content-start">
                                    <EnvelopeOpen size={24} color='white' />
                                    <div className="mx-4 numbers">
                                        <span className="info-text">Saldo Bulan {DataSaldoKas[0].periode.toLowerCase()}</span>
                                        <Card.Text>{DataSaldoKas[0].saldo.toLocaleString('en-us')}</Card.Text>
                                    </div>
                                </div>                                
                            </Row>
                        </Container>
                        
                        <Card.Header>
                            <div className="d-flex justify-content-start bg-">
                                <strong>PROYEKSI & ALOKASI BULAN INI</strong>
                            </div>
                        </Card.Header>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <EnvelopeOpen size={24} color='white' />
                                <div className="mx-4 numbers">
                                    <span className="info-text">Alokasi Biaya Bulanan Komplek</span>
                                    <Card.Text className="text-danger">{DataSaldoKas[0].alokasiBulanan.toLocaleString('en-us')}</Card.Text>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <EnvelopeOpen size={24} color='white' />
                                <div className="mx-4 numbers">
                                    <span className="info-text">Alokasi THR Karyawan</span>
                                    <Card.Text className="text-danger">{DataSaldoKas[0].alokasiTHR.toLocaleString('en-us')}</Card.Text>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <EnvelopeOpen size={24} color='white' />
                                <div className="mx-4 numbers">
                                    <span className="info-text">Alokasi PHBN</span>
                                    <Card.Text className="text-danger">{DataSaldoKas[0].alokasiPHBN.toLocaleString('en-us')}</Card.Text>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <EnvelopeOpen size={24} color='white' />
                                <div className="mx-4 numbers">
                                    <span className="info-text">Alokasi Puasa/Takjil Karyawan</span>
                                    <Card.Text className="text-danger">{DataSaldoKas[0].alokasiPuasa.toLocaleString('en-us')}</Card.Text>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <EnvelopeOpen size={24} color='white' />
                                <div className="mx-4 numbers">
                                    <span className="info-text">Alokasi Dana Kematian</span>
                                    <Card.Text className="text-danger">{DataSaldoKas[0].alokasiDanaKematian.toLocaleString('en-us')}</Card.Text>
                                </div>
                            </div>

                        </Row>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <EnvelopeOpen size={24} color='white' />
                                <div className="mx-4 numbers">
                                    <span className="info-text">Titipan Tab. Ibu - ibu</span>
                                    <Card.Text className="text-danger">{DataSaldoKas[0].tabunganLadies.toLocaleString('en-us')}</Card.Text>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <EnvelopeOpen size={24} color='white' />
                                <div className="mx-4 numbers">
                                    <span className="info-text">Titipan Qurban</span>
                                    <Card.Text className="text-danger">{DataSaldoKas[0].titipanQurban.toLocaleString('en-us')}</Card.Text>
                                </div>
                            </div>
                            <hr />
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-start">                               
                                <div className="mx-4 numbers">
                                    <span className="info-text">Proyeksi Saldo ( Aman & Bersih)</span>                                    
                                    <Card.Text className="text-success"><EmojiSmileFill color="green" size={24} className="mx-2"/>{DataSaldoKas[0].saldoAkhir.toLocaleString('en-us')}</Card.Text>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <div className="d-flex justify-content-start">                                
                                <div className="mx-4 numbers">
                                    <span className="info-text">Hutang Bendahara Sampah 2021 - 2022</span>
                                    <Card.Text className="text-danger"><EmojiFrownFill color="red" size={24} className="mx-2"/>{DataSaldoKas[0].piutangKasSampah.toLocaleString('en-us')}</Card.Text>
                                </div>
                            </div>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        <div className="d-flex justify-content-start">
                            <InfoCircleFill size={46} color="blue" className="me-2" />
                            <span className="info-text">Detail pengeluaran dan pemasukan bisa dilihat pada bagian Laporan Keuangan.</span>
                        </div>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>
    )
}