import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function BannerKas() {
    return (
        
            <Card>
                <Card.Img src="/iklan/banner-kas.png">
                </Card.Img>
            </Card>
    )
}