import { Badge, Card, Col, Container, Row, Stack } from 'react-bootstrap'
import IklanList from '../../data/promote.json'
import Carousel from 'react-bootstrap/Carousel'

export default function promote() {
    const iklanList = IklanList
    return (
        <>
            <div className='bg-white pt-3 pb-2'>
                <Container>
                    <blockquote className="blockquote text-center">
                        <p className="mb-3">
                            <strong>Iklah Warga</strong>, kami membantu warga mengiklankan
                            usahanya, yang akan menjual atau menyewakan bloknya.
                        </p>
                        <footer className="blockquote-footer">Pengurus Komplek<cite title="Source Title">BCA</cite></footer>
                    </blockquote>
                </Container>
            </div>
            <div className='pb-2'>
                <Container>
                    <Row xs={1} className="g-4">
                        <Col>
                            <Stack gap={3} className='pt-3'>
                                {
                                    iklanList.map((dataIklan, p) => {
                                        return (
                                            <Card className='shadow bg-white rounded'>
                                                <Card.Body>
                                                    <div className='d-flex justify-content-between pb-2 border-bottom'>
                                                        <Badge>
                                                            <h3>
                                                                {dataIklan.iklan}
                                                            </h3>
                                                        </Badge>
                                                        <div>
                                                            <div className='Row'>
                                                                BLOK
                                                            </div>
                                                            <div className='Row'>
                                                                <strong>{dataIklan.blok}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-between text-muted info-text mx-2 pt-2 pb-2'>
                                                        <span>{dataIklan.lanta1} Lantai</span>
                                                        <span>{dataIklan.kamar} Kamar Tidur</span>
                                                        <span>{dataIklan.kamarMandi} Kamar Mandi</span>
                                                        <span>{dataIklan.carport} Carport</span>
                                                    </div>
                                                    <div className='d-flex justify-content-between pt-2 mx-2 border-top'>
                                                        <span><strong>{dataIklan.harga}</strong></span>
                                                    </div>
                                                    <div className='d-flex justify-content-between pb-4 mx-2 info-text text-muted '>
                                                        <span>Telp. <strong>{dataIklan.telp}</strong> ({dataIklan.nama})</span>
                                                    </div>
                                                    <Carousel interval={null}>
                                                        {
                                                            dataIklan.images.map((dataImg, i) => {
                                                                return (
                                                                    <Carousel.Item key={i}>
                                                                        <img
                                                                            className='d-blok w-100'
                                                                            src={dataImg.image}
                                                                            alt='image1'
                                                                        />
                                                                        <Carousel.Caption>
                                                                            {/* <h3>DISEWAKAN!</h3> */}
                                                                        </Carousel.Caption>
                                                                    </Carousel.Item>
                                                                )
                                                            })
                                                        }
                                                    </Carousel>
                                                </Card.Body>
                                            </Card>
                                        )
                                    })

                                }
                            </Stack>
                        </Col>
                    </Row>
                </Container>
            </div>       
        </>

    )
}